import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/logo-white.png';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isProductsDropdownOpen, setIsProductsDropdownOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleProductsDropdown = () => {
    setIsProductsDropdownOpen(!isProductsDropdownOpen);
  };

  const scrollToServices = () => {
    const servicesSection = document.getElementById("services");
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: "smooth" });
    } else {
      console.warn("A seção de serviços não foi encontrada.");
    }
  };

  useEffect(() => {
    if (window.location.hash === "#services") {
      scrollToServices();
    }
  }, []);

  return (
    <header className="block px-8 bg-pattern z-50 w-full absolute">
      <nav className="w-11/12 mx-auto py-6 flex justify-between items-center">
        <div>
          <a href="/" className="inline-block">
            <img src={logo} alt="Logo de um pássaro branco, representando um falcão-peregrino de asas abertas, em estilo minimalista, sobre um fundo azul circular. Ao lado direito da logo, está escrito 'Hawx', e abaixo, a palavra 'Soluções'." className="w-32 xl:w-44" />
          </a>
        </div>
        <div className="md:hidden">
          <button
            className="text-white hover:text-black focus:outline-none p-2"
            onClick={toggleMobileMenu}
            aria-label={isMobileMenuOpen ? "Fechar menu" : "Abrir menu"}
          >
            {isMobileMenuOpen ? (
              <FontAwesomeIcon icon={faTimes} className="text-xl" />
            ) : (
              <FontAwesomeIcon icon={faBars} className="text-xl" />
            )}
          </button>
        </div>
        <ul className="hidden md:flex space-x-4 items-center">
          <li>
            <a href="/" className="text-white hover:text-black font-semibold">
              Início
            </a>
          </li>
          <li>
            <a
              href="../#services"
              className="text-white hover:text-black font-semibold"
            >
              Serviços
            </a>
          </li>
          <li className="relative">
            <button
              className="text-white hover:text-black font-semibold focus:outline-none"
              onClick={toggleProductsDropdown}
              aria-haspopup="true"
              aria-expanded={isProductsDropdownOpen ? "true" : "false"}
            >
              Produtos
            </button>
            {isProductsDropdownOpen && (
              <ul className="absolute bg-white text-black shadow-md mt-2 space-y-2 py-2 px-4 rounded w-64 ">
                <li>
                  <button className="group hover:text-gray-700 block text-left h-6 hover:h-12" aria-disabled="true">
                    CRM (Em desenvolvimento)
                    <p className="text-red-500 opacity-0 group-hover:opacity-100">
                      Indisponível no momento
                    </p>
                  </button>
                </li>
              </ul>
            )}
          </li>
          <li>
            <a href="/blogs" className="text-white hover:text-black font-semibold">
              Blog
            </a>
          </li>
          <li>
            <a href="/sobre-nos" className="text-white hover:text-black font-semibold">
              Sobre nós
            </a>
          </li>
          <li>
            <a
              href="https://api.whatsapp.com/send?phone=5548999631298"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-black font-semibold"
            >
              Contato
            </a>
          </li>
        </ul>
        <div
          className={`fixed inset-0 bg-pattern z-40 transform ${isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
            } transition-transform duration-300 ease-in-out`}
        >
          <button
            className="absolute top-6 right-6 text-white text-3xl focus:outline-none"
            onClick={toggleMobileMenu}
            aria-label="Fechar menu"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <ul className="flex flex-col items-center justify-center h-screen">
            <li className="mb-8">
              <a
                href="/"
                className="text-white text-2xl hover:text-black block"
              >
                Início
              </a>
            </li>
            <li className="mb-8">
              <a
                href="../#services"
                className="text-white text-2xl hover:text-black block"
                onClick={toggleMobileMenu}
              >
                Serviços
              </a>
            </li>
            <li className="mb-8">
              <button
                className="text-white w-full text-2xl hover:text-black block focus:outline-none text-center"
                onClick={toggleProductsDropdown}
                aria-haspopup="true"
                aria-expanded={isProductsDropdownOpen ? "true" : "false"}
              >
                Produtos
              </button>
              {isProductsDropdownOpen && (
                <ul className="mt-4 flex flex-col items-center space-y-4">
                  <li>
                    <button
                      className="text-white text-xl block cursor-default"
                      aria-disabled="true"
                    >
                      CRM (Em desenvolvimento)
                      <p className="text-red-900">
                        Indisponível no momento
                      </p>
                    </button>
                  </li>
                </ul>
              )}
            </li>
            <li className="mb-8">
              <a
                href="/blogs"
                className="text-white text-2xl hover:text-black block"
              >
                Blog
              </a>
            </li>
            <li className="mb-8">
              <a
                href="/sobre-nos"
                className="text-white text-2xl hover:text-black block"
              >
                Sobre nós
              </a>
            </li>
            <li className="mb-8">
              <a
                href="https://api.whatsapp.com/send?phone=5548999631298"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-2xl hover:text-black block"
              >
                Contato
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;