import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Whatsapp from "../components/util/Whatsapp";
import { useDebounce } from "../components/util/useDebounce";
import { blogPosts } from "../components/data/blogPosts";

function Blogs() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    if (debouncedSearchQuery.trim()) {
      const results = blogPosts.filter((item) =>
        item.title.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults(blogPosts);
    }
  }, [debouncedSearchQuery]);

  return (
    <>
      <Header />
      <div className="max-w-7xl mx-auto p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 pt-32">
        <div className="col-span-full mb-6">
          <input
            type="text"
            placeholder="Buscar posts..."
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
        </div>
        <div className="col-span-full">
          <h1 className="text-3xl font-bold mb-6">Todos os Posts</h1>
          <div className="space-y-6">
            {searchResults.length === 0 ? (
              <p className="text-gray-600">Nenhum post encontrado.</p>
            ) : (
              searchResults.map((post) => (
                <div key={post.id} className="flex items-center space-x-4 border-b pb-4">
                  <img
                    src={post.coverImage}
                    alt={post.title}
                    className="w-24 h-24 rounded-lg object-cover"
                  />
                  <div>
                    <h3 className="text-xl font-semibold">
                      <Link to={post.url} className="text-blue-600 hover:underline">
                        {post.title}
                      </Link>
                    </h3>
                    <p className="text-gray-600">{post.description}</p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <Whatsapp />
      <Footer />
    </>
  );
}

export default Blogs;
