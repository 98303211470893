import React, { useEffect, useState } from "react";

const About = () => {
  return (
    <div className="bg-pattern-gradient xl:pt-48 py-16 overflow-hidden">
      <div className="text-black w-3/4 mx-auto">
        <p className="xl:text-6xl text-3xl font-bold text-gradient text-center">Sobre nós</p>
        <div className="mt-8 xl:mt-16 mb-16">
          <p className="text-2xl text-white text-justify">
            Sou Eduardo, formado em Sistemas de Informação e orgulhosamente fundador da Hawx Soluções. Com um profundo interesse em empreendedorismo desde o início da minha carreira, minha empresa representa o resultado desse desejo de inovação e excelência em soluções tecnológicas. Estamos comprometidos em oferecer serviços de qualidade e soluções personalizadas para atender às necessidades de nossos clientes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
