import crm from '../../assets/crm.webp';
import crmHawx from '../../assets/crm-hawx.webp';
import eduardomeneghel from '../../assets/eduardomeneghel.webp';
import notion from '../../assets/notion.webp';
import notionLogo from '../../assets/notion-logo.webp';

export const blogPosts = [
  {
    id: "vantagens-do-crm-para-empresas",
    url: "/blog/vantagens-do-crm-para-empresas",
    title: "Vantagens do CRM para Empresas",
    description: "Explore como o CRM pode otimizar processos, melhorar o relacionamento com clientes e aumentar os resultados da sua empresa.",
    coverImage: crm,
    content: [
      {
        order: 0,
        type: "header",
        content: "O que é um CRM e por que ele é essencial?"
      },
      {
        order: 1,
        type: "text",
        content: "CRM (Customer Relationship Management, ou Gestão de Relacionamento com o Cliente) é uma estratégia que centraliza as interações com o cliente, auxiliando empresas a oferecerem um atendimento mais eficiente e personalizado. Mais do que apenas um software, o CRM une processos, tecnologia e estratégias voltadas para fidelizar e atrair clientes."
      },
      {
        order: 2,
        type: "imageFull",
        content: crmHawx,
      },
      {
        order: 3,
        type: "header",
        content: "O que ele faz?"
      },
      {
        order: 4,
        type: "text",
        content: "O CRM integra vendas, marketing e atendimento em uma única plataforma, facilitando o acesso às informações e permitindo um acompanhamento mais eficiente da jornada do cliente. Além disso, o sistema otimiza processos, centraliza dados e melhora a experiência em todas as etapas do relacionamento com o cliente."
      },
      {
        order: 5,
        type: "header",
        content: "Principais Vantagens do CRM"
      },
      {
        order: 6,
        type: "image1/2",
        content: crm,
      },
      {
        order: 7,
        type: "list",
        content: [
          "Centraliza informações e históricos de clientes, permitindo acesso rápido e organizado aos dados.",
          "Automatiza processos operacionais, como envio de e-mails e lembretes de follow-up.",
          "Aumenta a produtividade da equipe de vendas ao reduzir tarefas repetitivas.",
          "Facilita o acompanhamento de métricas e desempenho com relatórios detalhados.",
          "Ajuda a fidelizar clientes ao oferecer um atendimento personalizado e consistente.",
          "Permite acompanhar o progresso de cada oportunidade de venda em tempo real, priorizando os leads mais valiosos.",
          "Fortalece o relacionamento com clientes ao gerenciar todo o ciclo de vida, desde o primeiro contato até o pós-venda."
        ]
      },
      {
        order: 8,
        type: "header",
        content: "Impacto no Relacionamento com o Cliente"
      },
      {
        order: 9,
        type: "text",
        content: "Com um CRM, sua empresa pode criar uma experiência mais conectada e relevante para os clientes. O histórico detalhado de interações ajuda a entender as necessidades de cada cliente e a oferecer soluções personalizadas no momento certo, fortalecendo os laços e aumentando a fidelização.\nAlguns CRMs permitem integração com e-mail e WhatsApp, otimizando a comunicação e o marketing. Essas ferramentas ajudam a manter a marca presente, permitindo o envio de mensagens estratégicas que reforçam o relacionamento com os clientes. Com isso, é possível realizar ações de pré-venda, venda e pós-venda de forma eficiente, alinhando toda a experiência em uma única plataforma."
      },
      {
        order: 10,
        type: "header",
        content: "Aumentando a Eficiência Operacional"
      },
      {
        order: 11,
        type: "text",
        content: "O CRM automatiza tarefas rotineiras, como envio de e-mails de acompanhamento, agendamentos e atualizações de status. Isso reduz o tempo gasto em atividades repetitivas, permitindo que sua equipe se concentre no que realmente importa: fechar negócios."
      },
      {
        order: 12,
        type: "header",
        content: "Conclusão"
      },
      {
        order: 13,
        type: "text",
        content: "Investir em um CRM é um passo estratégico para qualquer empresa que deseja crescer de forma sustentável. Com benefícios que vão desde a organização interna até a satisfação do cliente, o CRM é uma ferramenta indispensável no mercado competitivo atual."
      }
    ],
    uploadDate: "2024-12-29T12:00:00Z",
    author: {
      name: "Eduardo Pedro Meneghel",
      photo: eduardomeneghel
    }
  },
  {
    id: "como-evitar-perda-de-dados-usando-notion",
    url: "/blog/como-evitar-perda-de-dados-usando-notion",
    title: "Como Evitar a Perda de Dados, Facilitar o Treinamento e Minimizar Impactos de Demissões Usando o Notion",
    description: "Aprenda a usar o Notion para organizar informações, otimizar treinamentos e lidar com a saída de funcionários sem comprometer a eficiência do seu negócio.",
    coverImage: notion,
    content: [
      {
        order: 0,
        type: "header",
        content: "Introdução: A Importância de uma Gestão de Conhecimento Eficiente"
      },
      {
        order: 1,
        type: "text",
        content: "Empresas enfrentam desafios como perda de informações, alta rotatividade e dificuldades com treinamento de novos colaboradores. Esses problemas podem levar a custos elevados, interrupções nos processos e baixa produtividade. Felizmente, ferramentas como o Notion podem ajudar a superar essas barreiras ao centralizar dados, padronizar treinamentos e simplificar a transição de responsabilidades."
      },
      {
        order: 2,
        type: "image1/2",
        content: notionLogo
      },
      {
        order: 3,
        type: "header",
        content: "1. Prevenindo a Perda de Dados"
      },
      {
        order: 4,
        type: "text",
        content: "A perda de dados ocorre frequentemente quando informações estão dispersas em diversas plataformas ou limitadas ao conhecimento de poucos colaboradores. No Notion, você pode criar um sistema unificado que armazene documentos importantes, manuais de procedimentos e dados críticos de forma acessível a todos da equipe."
      },
      {
        order: 5,
        type: "list",
        content: [
          "Organize uma 'Central de Conhecimento' com links e documentos categorizados.",
          "Crie páginas para cada departamento, com subpáginas dedicadas a projetos, relatórios e procedimentos.",
          "Implemente bases de dados com campos customizáveis para armazenar contatos, contratos e registros de clientes."
        ]
      },
      {
        order: 6,
        type: "header",
        content: "2. Facilitando Treinamento e Onboarding"
      },
      {
        order: 7,
        type: "text",
        content: "O treinamento de novos colaboradores pode ser simplificado com templates reutilizáveis e processos documentados no Notion. Isso reduz o tempo necessário para capacitar novos funcionários e melhora a consistência no aprendizado."
      },
      {
        order: 8,
        type: "list",
        content: [
          "Crie páginas de onboarding específicas para cada cargo, com instruções detalhadas.",
          "Adicione checklists para acompanhar o progresso dos treinamentos.",
          "Inclua vídeos, apresentações e links úteis diretamente nas páginas de treinamento."
        ]
      },
      {
        order: 9,
        type: "header",
        content: "3. Lidando com a Saída de Colaboradores"
      },
      {
        order: 10,
        type: "text",
        content: "A saída de um funcionário pode impactar negativamente o fluxo de trabalho, especialmente se o conhecimento não for adequadamente transferido. No Notion, é possível criar sistemas de transição que garantam que informações cruciais sejam documentadas e compartilhadas com a equipe."
      },
      {
        order: 11,
        type: "list",
        content: [
          "Implemente uma página de transferência de conhecimento, com detalhes de projetos e contatos.",
          "Documente fluxos de trabalho e processos críticos para facilitar a continuidade.",
          "Use funções de atribuição de tarefas para reorganizar responsabilidades durante a transição."
        ]
      },
      {
        order: 12,
        type: "header",
        content: "Benefícios de Usar o Notion na Gestão Empresarial"
      },
      {
        order: 13,
        type: "text",
        content: "Ao usar o Notion, sua empresa obtém uma gestão mais eficiente, com dados centralizados, processos documentados e treinamentos otimizados. Isso não apenas reduz os custos operacionais, mas também melhora a resiliência do negócio, permitindo que sua equipe se adapte rapidamente a mudanças."
      },
      {
        order: 14,
        type: "header",
        content: "Conclusão"
      },
      {
        order: 15,
        type: "text",
        content: "Implementar o Notion como ferramenta central da sua empresa é uma decisão estratégica. Ele permite prevenir a perda de dados, facilitar o treinamento e minimizar os impactos da rotatividade. Ao criar sistemas organizados e acessíveis, você transforma a gestão do conhecimento em uma vantagem competitiva, garantindo a continuidade do negócio e o sucesso a longo prazo."
      }
    ],
    uploadDate: "2024-01-18T12:00:00Z",
    author: {
      name: "Eduardo Pedro Meneghel",
      photo: eduardomeneghel
    }
  }
];