import React from "react";
import responsive from "../assets/responsive.png";

const Budget = () => {
  return (
    <>
      <div className="bg-pattern-gradient xl:pb-32">
        <div className="text-white w-3/4 mx-auto bg-cover bg-center">
          <div className="flex flex-col xl:flex-row xl:pt-48 pt-24 pb-16">
            <div className="xl2:w-1/2 xl:w-10/12 z-10 align-center">
               <img src={responsive} alt="Celular, notebook e caneca dispostos lado a lado. Na caneca, está estampada a logo da Hawx Soluções, com o pássaro branco minimalista e o texto 'Hawx Soluções'. Nas telas do celular e do notebook, é exibida a interface responsiva do sistema CRM da Hawx Soluções." className="h-auto mr-auto" />
            </div>
            <div className="xl:w-1/2 w-full z-10">
              <p className="xl:text-7xl text-5xl font-medium">Peça já o seu <strong className="text-gradient">orçamento</strong></p>
              <p className="text-2xl mt-12 mb-9 text-justify">Oferecemos soluções personalizadas e de alta qualidade para atender às suas necessidades com excelência.</p>
              <a href="https://api.whatsapp.com/send?phone=5548999631298" target="_blank" className="bg-opaque text-white py-6 px-8 rounded-2xl transition mt-8 w-full xl:w-2/3 block text-xl text-center">
                Peça seu orçamento
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Budget;
