import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Whatsapp from "../components/util/Whatsapp";
import BlogGenerator from "../components/BlogGenerator";
import { blogPosts } from "../components/data/blogPosts";

function Blog() {
  return (
    <>
      <Header />
      <BlogGenerator blogPosts={blogPosts} />
      <Whatsapp />
      <Footer />
    </>
  );
}

export default Blog;
